import React from 'react';

const ReactMigration = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M38.6996 30.4452C38.5113 30.4452 38.3308 30.3694 38.1977 30.2346C38.0646 30.0998 37.9897 29.917 37.9897 29.7264V12.9081H4.08834V29.7264C4.08131 29.9148 4.00438 30.0936 3.87276 30.2269C3.74113 30.3601 3.56453 30.438 3.37852 30.4452C3.19026 30.4452 3.00977 30.3694 2.87666 30.2346C2.74354 30.0998 2.6687 29.917 2.6687 29.7264V12.0742C2.6687 11.8836 2.74354 11.7008 2.87666 11.566C3.00977 11.4312 3.19026 11.3555 3.37852 11.3555H38.6996C38.8878 11.3555 39.0683 11.4312 39.2014 11.566C39.3345 11.7008 39.4094 11.8836 39.4094 12.0742V29.6113C39.426 29.7149 39.4195 29.8209 39.3914 29.9219C39.3632 30.0228 39.3134 30.1162 39.2458 30.1956C39.1781 30.2751 39.0942 30.3386 38.9997 30.3816C38.9053 30.4247 38.8032 30.4465 38.6996 30.4452Z"
      fill="#475467"
    />
    <path
      d="M38.728 36.9984H3.46383C1.33437 36.9984 0 33.7782 0 32.082C0 31.8914 0.0748377 31.7086 0.207954 31.5738C0.341071 31.4391 0.521562 31.3633 0.709817 31.3633H13.5434C13.7316 31.3633 13.9121 31.4391 14.0452 31.5738C14.1784 31.7086 14.2532 31.8914 14.2532 32.082C14.2603 32.1608 14.2966 32.2341 14.3544 32.2873C14.4122 32.3405 14.4877 32.3701 14.5658 32.3698H27.626C27.7042 32.3701 27.7796 32.3405 27.8375 32.2873C27.8953 32.2341 27.9316 32.1608 27.9387 32.082C27.9387 31.8914 28.0135 31.7086 28.1466 31.5738C28.2797 31.4391 28.4602 31.3633 28.6485 31.3633H41.482C41.6703 31.3633 41.8508 31.4391 41.9839 31.5738C42.117 31.7086 42.1919 31.8914 42.1919 32.082C42.1919 33.7782 40.8575 36.9984 38.728 36.9984ZM1.5049 32.8008C1.78882 34.0657 2.69722 35.6757 3.46383 35.6757H38.728C39.523 35.6757 40.403 34.1807 40.687 32.8008H29.3299C29.1882 33.1053 28.963 33.3621 28.6811 33.5405C28.3991 33.719 28.0722 33.8118 27.7397 33.8073H14.6788C14.3497 33.8135 14.0263 33.7213 13.7486 33.5423C13.4708 33.3633 13.2516 33.1053 13.1178 32.8008H1.5049Z"
      fill="#475467"
    />
    <path
      d="M9.14157 30.1563C8.58002 30.1563 8.0315 29.9875 7.56458 29.6716C7.09767 29.3557 6.73347 28.9069 6.51857 28.3816C6.30368 27.8562 6.24751 27.2782 6.35706 26.7205C6.46662 26.1629 6.73704 25.6504 7.13412 25.2483C7.5312 24.8463 8.03695 24.5724 8.58772 24.4615C9.13848 24.3506 9.70967 24.4075 10.2285 24.625C10.7473 24.8426 11.1906 25.2111 11.5025 25.6839C11.8145 26.1566 11.9808 26.7127 11.9808 27.2814C11.9808 28.0438 11.6822 28.7749 11.1497 29.314C10.6172 29.8532 9.89459 30.1563 9.14157 30.1563ZM9.14157 25.5561C8.83272 25.5561 8.5312 25.6491 8.2744 25.8228C8.0176 25.9966 7.81725 26.2435 7.69906 26.5324C7.58087 26.8214 7.55008 27.1392 7.61033 27.4459C7.67059 27.7527 7.81894 28.0344 8.03733 28.2556C8.25573 28.4767 8.53434 28.6273 8.83726 28.6883C9.14018 28.7493 9.45375 28.7181 9.73909 28.5985C10.0244 28.4788 10.2683 28.2759 10.4399 28.0159C10.6115 27.7559 10.7033 27.4502 10.7033 27.1375C10.7033 26.7181 10.5387 26.3159 10.2458 26.0194C9.95295 25.7228 9.55573 25.5561 9.14157 25.5561Z"
      fill="#475467"
    />
    <path
      d="M20.6133 21.9921C20.0586 21.964 19.5244 21.7719 19.0765 21.4394C18.6287 21.1069 18.2869 20.6488 18.0936 20.1216C17.9003 19.5944 17.8639 19.0215 17.9889 18.4736C18.114 17.9257 18.395 17.4271 18.7972 17.0393C19.1993 16.6514 19.7049 16.3915 20.2515 16.2917C20.798 16.192 21.3615 16.2565 21.8721 16.4777C22.3827 16.6989 22.8179 17.067 23.124 17.5362C23.4302 18.0054 23.594 18.5549 23.5947 19.1172C23.5952 19.5072 23.5174 19.8933 23.3659 20.2518C23.2144 20.6103 22.9925 20.9339 22.7136 21.2029C22.4348 21.4718 22.1049 21.6802 21.7439 21.816C21.3829 21.9517 20.9979 22.0116 20.6133 21.9921ZM20.6133 17.3923C20.3045 17.3923 20.0022 17.4853 19.7454 17.659C19.4886 17.8328 19.289 18.0797 19.1708 18.3686C19.0526 18.6576 19.0218 18.9754 19.0821 19.2821C19.1423 19.5889 19.2907 19.8706 19.5091 20.0918C19.7275 20.3129 20.0061 20.4635 20.309 20.5245C20.6119 20.5855 20.9255 20.554 21.2108 20.4343C21.4962 20.3146 21.74 20.1121 21.9116 19.8521C22.0832 19.5921 22.175 19.2864 22.175 18.9737C22.175 18.5543 22.0104 18.1521 21.7175 17.8556C21.4247 17.559 21.0275 17.3923 20.6133 17.3923Z"
      fill="#475467"
    />
    <path
      d="M32.084 26.0471C31.5293 26.019 30.9951 25.8269 30.5472 25.4944C30.0994 25.1619 29.7576 24.7037 29.5643 24.1766C29.371 23.6494 29.3346 23.0764 29.4596 22.5285C29.5847 21.9806 29.8657 21.4817 30.2679 21.0939C30.67 20.706 31.1756 20.4461 31.7222 20.3463C32.2687 20.2466 32.8322 20.3115 33.3428 20.5327C33.8534 20.7539 34.2886 21.1216 34.5947 21.5908C34.9009 22.06 35.0647 22.6098 35.0654 23.1722C35.0659 23.5621 34.9881 23.9483 34.8366 24.3068C34.6851 24.6653 34.4632 24.9889 34.1843 25.2578C33.9055 25.5268 33.5749 25.7352 33.2139 25.8709C32.8529 26.0067 32.4686 26.0666 32.084 26.0471ZM32.084 21.4473C31.6698 21.4473 31.2726 21.614 30.9798 21.9105C30.6869 22.207 30.5223 22.6093 30.5223 23.0286C30.5184 23.2373 30.5561 23.4446 30.6332 23.6382C30.7103 23.8318 30.8257 24.0075 30.9715 24.1552C31.1172 24.3028 31.2904 24.4193 31.4816 24.4973C31.6728 24.5754 31.8779 24.6135 32.084 24.6096C32.4982 24.6096 32.8954 24.4429 33.1882 24.1464C33.4811 23.8499 33.6457 23.448 33.6457 23.0286C33.6457 22.6093 33.4811 22.207 33.1882 21.9105C32.8954 21.614 32.4982 21.4473 32.084 21.4473Z"
      fill="#475467"
    />
    <path
      d="M33.7878 22.2515C33.6847 22.2506 33.5829 22.2271 33.4897 22.1824C33.3966 22.1377 33.3138 22.0727 33.2478 21.9925C33.1178 21.8582 33.0454 21.6777 33.0454 21.4896C33.0454 21.3015 33.1178 21.1207 33.2478 20.9864L45.6558 10.2053C45.7884 10.0736 45.967 10 46.1528 10C46.3386 10 46.5164 10.0736 46.6491 10.2053C46.781 10.3445 46.855 10.5301 46.855 10.7229C46.855 10.9158 46.781 11.1011 46.6491 11.2402L34.2418 22.0213C34.1236 22.1521 33.9621 22.2342 33.7878 22.2515Z"
      fill="#475467"
    />
    <path
      d="M29.8969 22.9993H29.6696L22.4861 20.4693C22.3092 20.4045 22.1653 20.2709 22.0855 20.0984C22.0056 19.9259 21.9966 19.7284 22.0605 19.5492C22.0888 19.4581 22.1357 19.374 22.1978 19.3021C22.2599 19.2302 22.3362 19.1721 22.4217 19.1315C22.5071 19.091 22.5998 19.069 22.6941 19.0666C22.7884 19.0643 22.882 19.0816 22.9693 19.1179L30.1527 21.6478C30.3296 21.7127 30.4735 21.8463 30.5534 22.0188C30.6332 22.1913 30.6422 22.3888 30.5783 22.568C30.518 22.6988 30.4213 22.8091 30.3003 22.8856C30.1794 22.9621 30.0395 23.0018 29.8969 22.9993Z"
      fill="#475467"
    />
    <path
      d="M11.0165 26.4476C10.8994 26.4465 10.7839 26.4173 10.6803 26.3623C10.5766 26.3073 10.4871 26.2282 10.4196 26.1314C10.3652 26.0541 10.3269 25.9667 10.3059 25.8742C10.285 25.7816 10.2825 25.6859 10.2983 25.5923C10.3142 25.4988 10.348 25.4093 10.3981 25.3291C10.4483 25.249 10.5134 25.1797 10.5902 25.1252L18.3413 19.6052C18.4177 19.5505 18.5044 19.5114 18.5957 19.4905C18.687 19.4695 18.7814 19.4671 18.8737 19.4831C18.9659 19.4991 19.0544 19.5332 19.1336 19.5838C19.2128 19.6344 19.2813 19.7005 19.3353 19.7779C19.3894 19.8553 19.4276 19.9427 19.4483 20.0351C19.469 20.1276 19.4718 20.2231 19.4559 20.3166C19.4401 20.41 19.406 20.4992 19.3561 20.5794C19.3062 20.6596 19.2412 20.7293 19.1648 20.784L11.4137 26.3037C11.2995 26.393 11.1606 26.4435 11.0165 26.4476Z"
      fill="#475467"
    />
    <path
      d="M45.5984 15.1798C45.5046 15.1658 45.4146 15.133 45.3336 15.0833C45.2525 15.0335 45.1819 14.968 45.1263 14.8903C45.0707 14.8126 45.0312 14.7244 45.0098 14.6309C44.9885 14.5374 44.9861 14.4408 45.0022 14.3463L45.5984 10.6375C45.6086 10.5438 45.6374 10.453 45.6836 10.3711C45.7298 10.2892 45.7921 10.2178 45.8666 10.1613C45.9411 10.1047 46.0263 10.0642 46.1169 10.0423C46.2074 10.0203 46.3017 10.0176 46.3934 10.0339C46.4872 10.0478 46.5772 10.0807 46.6582 10.1304C46.7392 10.1801 46.8092 10.2457 46.8648 10.3234C46.9204 10.4011 46.9606 10.4893 46.9819 10.5828C47.0033 10.6762 47.0057 10.7728 46.9896 10.8674L46.3934 14.5762C46.3832 14.6699 46.3536 14.7606 46.3075 14.8425C46.2613 14.9244 46.1997 14.9958 46.1252 15.0524C46.0507 15.109 45.9655 15.1494 45.8749 15.1714C45.7844 15.1933 45.6901 15.1961 45.5984 15.1798Z"
      fill="#475467"
    />
    <path
      d="M46.1658 11.4687H42.503C42.3148 11.4687 42.1343 11.3929 42.0012 11.2582C41.8681 11.1234 41.7932 10.9406 41.7932 10.75C41.7932 10.5594 41.8681 10.3766 42.0012 10.2418C42.1343 10.107 42.3148 10.0312 42.503 10.0312H46.1658C46.3541 10.0312 46.5345 10.107 46.6677 10.2418C46.8008 10.3766 46.8756 10.5594 46.8756 10.75C46.8756 10.9406 46.8008 11.1234 46.6677 11.2582C46.5345 11.3929 46.3541 11.4687 46.1658 11.4687Z"
      fill="#475467"
    />
  </svg>
);

export default ReactMigration;
